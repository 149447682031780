import cx from 'classnames';
import PropTypes from 'prop-types';

const BrandLogosInDesktop = ({ popularBrands }) => {
    const groupBrandLogos = [
        {
            id: 1,
            content: [
                { ...popularBrands.google },
                { ...popularBrands.master },
                { ...popularBrands.grab },
                { ...popularBrands.loreal },
                { ...popularBrands.unilever },
                { ...popularBrands.accenture },
                { ...popularBrands.sourceNext },
            ],
        },
        {
            id: 2,
            content: [
                { ...popularBrands.united, className: 'BrandLogosInDesktop__luminosity' },
                { ...popularBrands.ogilvy },
                { ...popularBrands.merck },
                { ...popularBrands.accor },
                { ...popularBrands.chevron },
                { ...popularBrands.bayer },
                { ...popularBrands.marriott },
            ],
        },
    ];

    return (
        <>
            <div className="BrandLogosInDesktop__container">
                {groupBrandLogos.map((row) => (
                    <div
                        className="BrandLogosInDesktop__rows"
                        key={`BrandLogosInDesktop-row-${row.id}`}
                    >
                        {row.content.map((item) => (
                            <picture key={item.id}>
                                <source srcSet={item.imageWebp} type="image/webp" />
                                <img
                                    key={item.id}
                                    className={cx({
                                        [item.className]: item.className,
                                    })}
                                    src={item.image}
                                    width="auto"
                                    height="34px"
                                    alt={item.alt}
                                    loading="lazy"
                                />
                            </picture>
                        ))}
                    </div>
                ))}
            </div>

            <style jsx>
                {`
                    .BrandLogosInDesktop {
                        &__container {
                            width: fit-content;
                            display: flex;
                            padding: 32px;
                            flex-direction: column;
                            align-items: center;
                            gap: 40px;
                            border-radius: 20px;
                            margin: 0 auto 0 auto;
                            background: #ffffff;
                            box-shadow: 0px 12px 24px 0px rgba(145, 158, 171, 0.24),
                                0px 0px 2px 0px rgba(145, 158, 171, 0.24);
                        }

                        &__rows {
                            display: flex;
                            align-items: flex-start;
                            gap: 50px;
                        }

                        &__luminosity {
                            mix-blend-mode: luminosity;
                        }
                    }
                `}
            </style>
        </>
    );
};

BrandLogosInDesktop.propTypes = {
    popularBrands: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default BrandLogosInDesktop;
